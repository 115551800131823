<template>
  <div class="animated fadeIn">

    <b-row>
      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <h3>Quotes</h3>
          <b-button :to="{ name: 'Quotes' }" variant="outline-dark" class="mt-3 mx-5">View Quotes</b-button>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <h3>Policies</h3>
          <b-button :to="{ name: 'Policies' }" variant="outline-dark" class="mt-3 mx-5">View Policies</b-button>
        </div>
      </b-col>

      <b-col lg="4">
        <div class="card bg-light py-4 text-center">
          <h3>Search</h3>
          <b-button :to="{ name: 'Search' }" variant="outline-dark" class="mt-3 mx-5">Go to Search</b-button>
        </div>
      </b-col>

    </b-row><!--/.row-->

  </div>

</template>

<script>

export default {
  name: 'CustomerService',
  data: () => {
    return {

    }
  }
}
</script>
